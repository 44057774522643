var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};

(function ($) {
  $(document).on('inv_status_data:updated', '.js-product', function (e) {
    var $product = $(this);
    var $statusList = $('.js-inv-status-list', $product);
    var skuBaseId = $statusList.data('sku-base-id');
    var skuData = prodcat.data.getSku(skuBaseId);

    if (!!skuData) {
      Drupal.behaviors.inventoryStatusV1.update($statusList, skuData, $product);
    }
  });

  $(document).on('product.skuSelect', '.js-product', function (e, skuBaseId) {
    var $product = $(this);
    var $statusList = $('.js-inv-status-list', $product);

    if ($statusList.length < 1) {
      return null;
    }

    $statusList.data('sku-base-id', skuBaseId);
    var skuData = prodcat.data.getSku(skuBaseId);

    if (!!skuData) {
      Drupal.behaviors.inventoryStatusV1.update($statusList, skuData, $product);
    }
  });

  $(document).on('product.init', '.js-product', function (e) {
    var $product = $(this);
    var $statusList = $('.js-inv-status-list', $product);
    var skuBaseId = $statusList.data('sku-base-id');
    var skuData = prodcat.data.getSku(skuBaseId);

    if (!!skuData) {
      Drupal.behaviors.inventoryStatusV1.update($statusList, skuData, $product);
    }
  });

  Drupal.behaviors.inventoryStatusV1 = {
    update: function ($statusList, skuData, $product) {
      var self = this;
      var skuBaseId = $statusList.data('sku-base-id');
      var status = skuData.INVENTORY_STATUS;

      if (!skuData || !status) {
        return null;
      }
      if (skuData.SKU_ID !== 'SKU' + skuBaseId) {
        return null;
      }
      $('li', $statusList).hide();

      var $addBtns = $product.find('.js-add-to-cart, .js-mpp-shop-now');

      $addBtns.each(function () {
        var $addBtn = $(this);
        var $addBtnCopy = $addBtn.find('.js-add-to-cart__copy, .js-mpp-shop-now__copy');

        $addBtn.removeClass('button--disabled').data('disabled', false);
        self.updateBtnText($addBtn, $addBtnCopy, $addBtn.data('default-label'));
        var $statusToShow = $('.js-inv-status-' + status, $statusList);

        if ($statusToShow.length > 0) {
          var $btnLabels = $addBtn.data('inv-status-labels');

          if (!_.isUndefined($btnLabels)) {
            if (!!$btnLabels && !!$btnLabels[status]) {
              self.updateBtnText($addBtn, $addBtnCopy, $btnLabels[status]);
            }
            if (!skuData.isShoppable) {
              $addBtn.addClass('button--disabled').data('disabled', true);
            }
          }

          // Waitlist / Notify Me
          if (Drupal.settings.common && !!Drupal.settings.common.has_waitlist) {
            if (
              (!!Drupal.settings.common.display_notify_tos && status === 2) ||
              (!!Drupal.settings.common.display_notify_cs && status === 3) ||
              (!!Drupal.settings.common.display_notify_so && status === 7)
            ) {
              self.updateBtnText($addBtn, $addBtnCopy, $addBtn.data('notify-me'));
              $addBtn.removeClass('button--disabled').data('disabled', false);
              if (!Drupal.settings.common.enable_toos_cs_status_msg) {
                $statusList.find('.product__inventory-status-item').addClass('hidden');
              }
            }
          }

          if ($statusToShow.data('display') || (Drupal.settings.common.enable_toos_cs_status_msg && status == 3)) {
            $statusToShow.show();
          }
          $statusList.trigger('inv_status_display:updated');
        }
      });
    },
    updateBtnText: function ($addBtn, $addBtnCopy, label) {
      if ($addBtnCopy.length > 0) {
        $addBtnCopy.text(label);
      } else {
        $addBtn.text(label);
      }
    }
  };
})(jQuery);
